
import { Vue, Component } from 'vue-property-decorator';
import { getChallengeList, getChallengeCategory } from '@/api/challenge';
import Pagination from '@/components/Pagination/index.vue';

@Component({
	components: {
		Pagination,
	},
})
export default class extends Vue {
	mounted() {
		this.getChallengeList();
		this.getChallengeCategory();
	}

	private now = new Date();

	private loading = true;

	private apiUrl = process.env.VUE_APP_COMMON_API;

	private totalElements = 0;

	private listQuery = {
		categoryUid: '',
		myFlag: false,
		page: 0,
		size: 5,
	}

	private challengeList: any = [];

	private categoryList: any = [];

	private getChallengeList() {
		if (this.$route.params.myFlag) {
			this.listQuery.myFlag = true;
		}
		getChallengeList(this.listQuery).then((res) => {
			this.challengeList = res.data;
		}).catch(() => {
			this.$message.warning('챌린지 리스트를 조회하는데 실패했습니다.');
		});
		this.loading = false;
	}

	private getChallengeCategory() {
		getChallengeCategory().then((res) => {
			this.categoryList = res.data;
		}).catch(() => {
			this.$message.warning('챌린지 리스트를 조회하는데 실패했습니다.');
		});
		this.loading = false;
	}

	private searchChallengeList(categoryUid: string) {
		this.listQuery.categoryUid = categoryUid;
		this.getChallengeList();
	}
}

